<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from "@/store"
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            type: null,
            titre: null,

            id: null,
            validuser: false,
            proprieters: [],
            abonnement: false,
            categories: null,
            currentPage: 1, // Page actuelle
            itemsPerPage: 6,
            pays: null,
            country: null,
            cat_fil: null,

        }
    }
    ,
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'SearchComponent',
    mounted() {
        this.proprie()

        this.getcategorie()
        store.dispatch('categorie/getcountry')
            .then(response => {
                this.country = response

            })


        store.dispatch('categorie/getabonnement')
            .then(response => {
                console.log(response)
                response.forEach(resp => {
                    if (resp.categorie.id === this.id) {
                        this.abonnement = true
                    }

                })
            })
            .catch(error => {
                console.log(error)

            })
        store.dispatch('auth/_currentUser')
            .then(() => {

                this.validuser = true
                console.log(this.validuser)
            })
            .catch(error => {
                console.log(error)

            })
    },
    computed: {
        totalPages() {
            return Math.ceil(this.proprieters.length / this.itemsPerPage);
        },
        paginatedProprieters() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.proprieters.slice(startIndex, endIndex);
        },
    },
    methods: {
        // Méthode pour changer de page

        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },
        setPage(pageNumber) {
            this.currentPage = pageNumber;
            window.location.href = '#vupro'

        }, getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    console.log(response)
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        hasard(fin) {
            return Math.floor(Math.random() * fin)
        },
        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        proprie() {
            const urlParams = new URLSearchParams(window.location.search);
            this.type = urlParams.get('type');
            this.id = urlParams.get('id');
            if (this.type == 'search') {
                this.titre = "RECHERCHE:" + this.id
                document.title = "RECHERCHE: " + this.id
            }
            else if (this.type == 'a-louer') {
                document.title = "Bénin location avec TGV Immo Brio : louez à Cotonou maintenant"
                document.description =  "Découvrez les meilleures offres de location au Bénin avec TGV Immo Brio. Louez des appartements, maisons et villas à Cotonou et dans tout le Bénin !"
            this.type = 'a louer'
            }

            else if (this.type == 'a-vendre') {
                document.title = "Meilleurs biens immobiliers à vendre Bénin"
                document.description = "Découvrez des maisons, villas et terrains à vendre au Bénin avec TGV Immo Brio, votre agence immobilière de référence !"
           this.type = 'a vendre'
            }
            store.dispatch('proprieter/proprietersearch', { 'type': this.type, 'id': this.id })
                .then(response => {
                    console.log(response)
                    this.proprieters = response
                    if (this.type == 'search') {
                        this.titre = "RECHERCHE:" + this.id
                    }
                    else if (this.type == 'categorie') {
                        this.titre = "CATEGORIE: " + response[0].categorie.nom

                    }
                    else if (this.type == 'user') {
                        if (response[0].user.entreprise) {
                            this.titre = "UTILISATEUR: " + response[0].user.entreprise
                        }
                        else {
                            this.titre = "UTILISATEUR: " + response[0].user.first_name + ' ' + response[0].user.last_name
                        }
                    }
                    else if (this.type == 'a louer') {
                        this.titre = "RECHERCHE: A louer"

                    }
                    else if (this.type == 'a vendre') {
                        this.titre = "RECHERCHE: A vendre"
                    }
                })
                .catch(error => {
                    console.log(error)
                })

        },
        trieproprieter(trie) {
            if (trie === 'all') {
                // Si trie est 'all', afficher tous les éléments en réinitialisant les filtres
                this.proprie()
                // Réinitialiser les valeurs des filtres
                this.pays = null
                this.cat_fil = null;
            } else if (trie === 'filtre') {

                const formData = new FormData()
                if (this.id != null) {
                    formData.append('id', this.id)

                }
                formData.append('type', this.type)



                if (this.cat_fil != null) {

                    formData.append('cat_fil', this.cat_fil)

                }

                if (this.pays != null) {

                    formData.append('pays', this.pays)
                }
                store.dispatch('proprieter/proprieterfilter', formData)
                    .then(response => {
                        this.proprieters = response

                        window.location.href = '#vupro'

                    }).catch(error => {
                        console.log(error)
                        Swal.fire({
                            title: "Erreur",
                            text: "Aucun resultat trouver",
                            icon: "error",
                            cancelButtonColor: "#f46a6a",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        })

                        document.getElementById('btn_dmd').click()
                    })

            }
        },
        subscribeToCategory() {
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('categorie/abonner', { 'id': this.id })
                        .then(response => {

                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {

                    Swal.fire({
                        title: "Erreur",
                        text: "Erreur" + error + " lors de la récupération de l'utilisateur veuillez vous connecter ou vous inscrire",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                }
                );
        }
    }
}
</script>

<template>
    <HeadComponent />
    <main>
        <section>
            <div class="container-fluid">
                <div class="row" v-if="type == 'categorie'">
                    <div class="col-sm-12">
                        <div class="area-title mb-5">
                            <h3>
                                <center>{{ titre }}</center>
                            </h3>
                            <button v-if="type == 'categorie' & !abonnement" class="btn theme-btn"
                                @click="subscribeToCategory">S'abonner
                                à la catégorie</button>

                            <button v-if="type == 'categorie' & abonnement" class="btn theme-btn"
                                @click="subscribeToCategory">Se désabonner
                                de la catégorie</button>
                        </div>

                    </div>


                </div>
                <section v-if="type == 'a louer'" class="about-uss row">

                    <section>
                        <div class="background-div d-flex justify-content-center align-items-center">
                            <div class="background-content">
                                <h1>Immobilier à louer : découvrez les meilleures offres de location au Bénin
                                </h1>
                                <p>Bienvenue sur la page "Immobilier à Louer" de <strong>TGV Immo Brio</strong> , la plateforme de référence pour toutes vos<strong> locations immobilières à Cotonou</strong>, au Bénin et dans toute l'Afrique. Que vous soyez à la recherche d'un <strong>appartement</strong>, d'une <strong>villa </strong>ou d'une <strong>maison à louer</strong>, nous vous offrons une <strong>large sélection d'annonces </strong>répondant à vos besoins et à votre budget. Que vous cherchiez un bien pour un séjour de courte ou longue durée, nous avons ce qu’il vous faut.


                                </p>
                            </div>
                        </div>
                        <div class="container mt-4">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="../assets/ventee.jpg" alt="Image d'illustration" class="img-fluid"
                                        style="border-radius: 8px;">
                                </div>

                                <div class="col-md-6 ">
                                    <h3 class="text-center">Pourquoi Choisir TGV Immo Brio pour Votre Location au Bénin ?
                                    </h3>
                                    <p>Nous mettons à votre disposition des <strong>informations claires et détaillées</strong>  pour faciliter votre recherche d'appartements ou de villas en location au Bénin, en particulier à Cotonou, Calavi, et les environs. Nos <a class="text-primary" href="https://immobilier.lefigaro.fr/">annonces immobilières</a>  incluent <strong> des options de logements pour tous les types de besoins</strong> : appartements meublés, maisons spacieuses, chambres avec salles de bain privatives, ou encore villas de standing. Vous pouvez <strong>facilement visualiser toutes les infos nécessaires</strong>, que ce soit le nombre de chambres, de salles de bain, ou les commodités disponibles.
                                    </p>
                                    <p>Que vous soyez en quête d'un logement temporaire pour vos vacances à Cotonou ou d'une location à long terme dans un quartier prisé comme Fidjrossè, TGV Immo Brio vous aide à trouver l’appartement ou la maison qui correspond à vos critères.
                                    </p>
                                    <h2>Large Sélection de Locations à Cotonou et au Bénin
                                    </h2>
                                    <h3 class="text-center">Appartements à Louer</h3>
                                    <p>Si vous recherchez un <strong> appartement en location à Cotonou</strong>, vous trouverez une grande variété d'options adaptées à votre style de vie et à votre budget. Nous disposons d'<strong>appartements avec 1, 2, ou 3 chambres</strong> , certains dotés de salles de bain modernes et d'espaces de couchage confortables. Nos locations peuvent convenir aussi bien à ceux qui recherchent un espace temporaire pour leurs vacances qu'à ceux qui souhaitent s’installer à Cotonou sur une plus longue période.
                                    </p>
                                    <h3 class="text-center">Maisons et Villas à Louer

                                    </h3>
                                    <p>Vous êtes plutôt à la recherche d'une maison ou d'une villa ? Nos annonces incluent <strong> des villas élégantes</strong> et <strong>des maisons spacieuses avec plusieurs chambres</strong> et salles de bain, idéales pour les familles nombreuses ou les groupes d’amis. Ces villas offrent souvent des équipements modernes tels que des piscines, des jardins privés, et des espaces de couchage spacieux pour des séjours prolongés.
                                    </p>
                                    <h3 class="text-center">Chambres à Louer

                                    </h3>
                                    <p>Si vous êtes à la recherche d'<strong>une chambre en location à Cotonou</strong>, nous proposons également<strong> des solutions adaptées aux petits budgets</strong>. Que ce soit pour une nuitée, quelques jours, ou une location à plus long terme, nous avons des chambres disponibles dans différents quartiers comme Fidjrossè ou Calavi.
                                    </p>
                                 
                                </div>

                            </div>
                        </div>                            
                                    <h3 class="text-center">Informations Complètes et Avis Clients
                                    </h3>
                                    <p class="m-5">
Chaque annonce de location sur notre plateforme fournit des <strong>infos détaillées</strong>, incluant le <strong>nombre de chambres</strong>, salles de bain, et <strong>couchages disponibles</strong>. Nous proposons également des <strong>avis clients </strong>pour vous permettre de faire un choix éclairé. Que vous cherchiez un appartement meublé pour une courte période ou une villa spacieuse pour une longue durée, nous avons la solution idéale pour vous.
</p>
                               <h3 class="text-center">Des options flexibles pour tous vos séjours
                            </h3>      
                            <p class='m-5'>Que ce soit pour un <strong>séjour professionnel</strong> ou des <strong>vacances</strong>, nous offrons des locations flexibles. Nos appartements, maisons et villas sont disponibles pour des durées variées, et certains de nos logements peuvent être loués pour une nuit (voir nuittotal dans les annonces) ou pour des périodes plus longues, en fonction de vos besoins.

                            </p>
                            <p class="m-5"><strong>TGV Immo Brio</strong> vous offre une expérience complète et transparente pour la location immobilière au <strong> Bénin</strong>. Nos offres couvrent<strong> Cotonou, Calavi</strong>, et d’autres villes clés, avec une gamme d’appartements, maisons, et villas de qualité supérieure. N'attendez plus, explorez nos annonces dès maintenant pour trouver le logement qui vous correspond et vivez une expérience de location sans souci au Bénin !
                            </p>
                    </section>
                </section>

                <section v-if="type == 'a vendre'" class="about-uss row">

                    <section>
                        <div class="background-div d-flex justify-content-center align-items-center">
                            <div class="background-content">
                                <h1>Biens immobiliers à vendre au Bénin : trouvez votre Nid Douillet avec TGV Immo brio
                                </h1>
                                <p>Vous recherchez un <strong> bien immobilier à vendre au Bénin ?</strong> <a
                                        href="https://tgvimmobrio.com/" class='text-primary'> TGV Immo Brio </a> est la
                                    plateforme idéale pour vous accompagner dans votre recherche de maison, appartement,
                                    ou villa à Cotonou, Calavi, ou ailleurs au Bénin. Que vous soyez expatrié à la
                                    recherche d'une maison à vendre au Bénin ou un investisseur cherchant des terrains à
                                    vendre, notre agence immobilière vous propose une large gamme d’annonces
                                    actualisées. Avec <strong>TGV Immo Brio</strong> , vous bénéficiez d’une expertise
                                    complète du marché immobilier béninois pour trouver le bien idéal à acheter ou à
                                    vendre.

                                </p>
                            </div>
                        </div>
                        <div class="container mt-4">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="../assets/loyer.jpg" alt="Image d'illustration" class="img-fluid"
                                        style="border-radius: 8px;">
                                </div>

                                <div class="col-md-6 ">
                                    <h3 class="text-center">Un  large choix de biens immobiliers à vendre au Bénin
                                    </h3>
                                    <p>Sur notre plateforme, vous pouvez explorer un large éventail de <strong>maisons, appartements, </strong> et <strong> villas à vendre au Bénin.</strong> Que vous soyez à la recherche d'une villa à vendre à Cotonou ou d'un <strong>terrain à vendre au Bénin</strong> , nous vous accompagnons à chaque étape pour garantir que votre expérience soit simple et réussie.
                                    </p>

                                    <h3 class="text-center">Maisons et villas à vendre
                                    </h3>
                                    <p>Nous disposons d'une sélection variée de maisons et villas à vendre à <a href="https://observatoirevivreensemble.org/cotonou"> Cotonou</a>, Calavi, et dans d’autres villes clés du Bénin. Que vous cherchiez une <strong> maison avec plusieurs chambres, des pièces spacieuses</strong>, ou une  <strong>villa de luxe </strong> avec piscine, TGV Immo Brio est votre interlocuteur de confiance. Grâce à notre réseau et nos contacts dans le secteur, nous proposons des biens à différents prix, qu’ils soient abordables ou haut de gamme, avec des options de financement en FCFA.
                                    </p>
                                    <h3 class="text-center">Appartements et terrains à vendre
                                    </h3>
                                    <p>Si vous préférez investir dans un appartement ou un  terrain pour un projet de construction, nous disposons également de nombreuses offres d’appartements neufs et d’appartements anciens à vendre. Nos <strong>terrains</strong> disponibles à Cotonou, Calavi, ou à proximité de la <strong>route des pêches</strong> en bordure de mer vous permettent de réaliser des projets d’investissement immobiliers à fort potentiel. Que ce soit pour un <strong>achat personnel </strong>ou pour un <strong>investissement à long terme,</strong>  nos offres sont accessibles et diversifiées.

                                       </p>
                                     
                                </div>

                            </div>
                        </div>
                        <h3 class="text-center">Pourquoi choisir TGV Immo Brio pour votre achat immobilier ? </h3>
                                    <ul class="text-start         m-5   ">
                                        <li><i class="fa fa-check text-primary me-3"></i>Un <strong>large choix de biens </strong>: maisons, villas, appartements, terrains... Nous proposons une grande variété de biens immobiliers à vendre, adaptés à tous les budgets et à tous les styles de vie.
                                        </li>
                                        <li><i class="fa fa-check text-primary me-3"></i><strong>Expérience et expertise locale</strong> : nous sommes présents sur le marché immobilier du Bénin depuis de nombreuses années et nous possédons une connaissance approfondie des dynamiques locales.
                                        </li>
                                        <li><i class="fa fa-check text-primary me-3"></i><strong>Des biens de qualité </strong>: nous sélectionnons rigoureusement nos biens afin de vous proposer des produits immobiliers de standing.
                                        </li>
                                        <li><i class="fa fa-check text-primary me-3"></i><strong>Des prix compétitifs </strong>: bénéficiez des meilleurs prix du marché grâce à notre réseau de partenaires privilégiés.
                                            </li>
                                        <li><i class="fa fa-check text-primary me-3"></i><strong>Accompagnement personnalisé</strong> : notre équipe d’experts vous guide et vous conseille tout au long du processus d'achat, de la visualisation des biens à la signature de l'acte de vente.
                                        </li>
                                      
                                    </ul>
                    </section>
                </section>


                <div class="area-title mb-50">
                    <form @submit.prevent="trieproprieter('filtre')">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="checkout-form-list country-select">
                                    <label class="form-label">Pays</label>
                                    <select required ref="mySelect" v-model="pays" class="form-control">
                                        <option v-for="countrie in country" :key="countrie.iso" :value="countrie.iso">{{
                                            countrie.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="checkout-form-list country-select">
                                    <label>Categorie </label>
                                    <select v-model="cat_fil" class="form-control">
                                        <option>choisir une categorie</option>
                                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
                                            cat.nom }}
                                        </option>

                                    </select>
                                </div>
                            </div>






                        </div>

                        <center>

                            <button type="submit" class="btn theme-btn-2 text-light">Lancer filtre</button>
                            <button type="reset" @click="trieproprieter('all')" class=" bg-dark text-light"><i
                                    class="fa fa-refresh"></i> </button>

                        </center>
                    </form>

                </div>
                <div class="row" v-if="proprieters" id="vupro">
                    <div v-for="proprieter in paginatedProprieters" :id="proprieter.id" :key="proprieter.id"
                        class="col-xl-4 col-lg-6 col-md-6">
                        <div class="latest-news mb-40">
                            <a :href="'/commentaire?id=' + proprieter.id">
                                <div class="news__thumb " style="position: relative;">

                                    <span v-if="proprieter.meuble" class="badge badge-danger badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; right: 0;">Meublé</span>
                                    <span class="badge badge-primary badge-lg"
                                        style="font-size:15px;position: absolute; top: 0; left: 0;">{{
                                        proprieter.type}}</span>



                                    <img v-if="proprieter.image[0].image"
                                        :src="proprieter.image[hasard(proprieter.image.length)].image" alt=""
                                        style='width:550px; height:350px'>
                                    <span class="badge badge-success "
                                        style="font-size:22px; position: absolute; bottom: 0; left: 0;">
                                        <i class="fa fa-location-arrow "></i> {{ proprieter.addresse }}
                                    </span>
                                </div>
                            </a>
                            <div class="news__caption white-bg">
                                <div class="news-meta mb-15">
                                    <span class="text-danger"><i class="far fa-dollar"></i>{{
                                        formatMonetaryValue(proprieter.prix )}} {{ proprieter.pays.currency_code }}
                                        /{{ proprieter.periode }} </span>
                                    <span class="text-danger"><a><i class="far fa-heart"></i> {{ proprieter.total_likes
                                            }}</a></span>
                                    <span class="text-danger"><a><i class="far fa-comments"></i>{{
                                        proprieter.total_comments
                                            }}</a></span><br />
                                    <span>{{ formatDate(proprieter.created_at) }}</span>
                                    <br />


                                    <span class="text-danger"><a><i class="fa fa-database "></i> {{
                                        proprieter.categorie.nom }}</a></span>
                                </div>
                                <h2 class="truncate">{{ proprieter.description }}</h2>

                              

                                <div class="social-profile" >
                                    <a target="_blank"  v-if='proprieter.user.email_contact' @click="upcontact_pro(proprieter.id)"
                                        :href="'mailto:' + proprieter.user.email + '?subject=' + encodeURIComponent('Demande de renseignement') + '&body=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fa fa-envelope"></i></a>
                                    <a target="_blank"  v-if='proprieter.user.whatsapp_contact' @click="upcontact_pro(proprieter.id)"
                                        :href="'https://wa.me/' + proprieter.user.whatsapp + '?text=' + encodeURIComponent('Bonjour, j\'ai vu ce bien sur TGV IMMO BRIO, ça m\'intéresse. https://tgvimmobrio.com/commentaire?id=' + proprieter.id)"><i
                                            class="fab fa-whatsapp"></i></a>
                                    <a :href="'/commentaire?id=' + proprieter.id"><i class="fa fa-eye"></i></a>
                                </div>

                                <div class="sharethis-inline-share-buttons"
                                    :data-url="'tgvimmobrio.com/commentaire?id=' + proprieter.id"
                                    :data-title="proprieter.categorie.nom" :data-description="proprieter.titre"
                                    :data-image="proprieter.image[0].image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination justify-content-center">
                    <ul class="pagination">
                        <li v-for="pageNumber in totalPages" :key="pageNumber" @click="setPage(pageNumber)"
                            class="page-item" :class="{ 'active': pageNumber === currentPage }">
                            <a class="page-link"> {{ pageNumber }} </a>
                        </li>
                    </ul>
                </div>

                <div v-if="!proprieters" class="alert alert-warning alert-dismissible fade show" role="alert">
                    <div class="alert-heading">
                        <h4 class="alert-title">Alert</h4>
                    </div>
                    <p>Aucun bien immobilier n'est trouvé</p>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </section>
        <section class='container-fluid m-5'>
<h3 class="text-center">Foire aux questions (FAQ)</h3>
      <h4>1.	Quelles sont les meilleures options pour acheter une maison au Bénin ?</h4>  
 <p>Les meilleures options incluent des maisons à vendre à Cotonou, Calavi, et des villes comme Abomey. TGV Immo Brio propose une sélection variée adaptée à tous les budgets, avec des maisons de plusieurs chambres.
</p>  <h4>
2.	Quels sont les quartiers les plus prisés pour acheter une maison à Cotonou ? </h4>   
<p>Les quartiers de Fidjrossè, Akpakpa et Houéyiho sont très recherchés pour leur calme et leur proximité des commodités.
</p>

<h4>
3.	Y a-t-il des villas à vendre à Cotonou pour expatriés ?</h4>
<p>Oui, nous avons une offre variée de villas à vendre à Cotonou adaptées aux expatriés, avec des espaces luxueux, des chambres spacieuses et des équipements modernes.
</p>
<h4>4.	Est-il possible d’acheter un terrain au Bénin pour construire une maison ?
</h4>
<p>Absolument. Nous proposons divers terrains à vendre au Bénin, idéalement situés pour la construction de votre future maison ou pour un investissement immobilier.
</p>
<h4>
5.	Quel est le budget moyen pour acheter une villa à Cotonou ? </h4>
<p>Le prix d'une villa à Cotonou varie en fonction de sa superficie, de son emplacement et de ses prestations. Il est possible de trouver des villas à partir de 50 000 000 FCFA.
</p>
<h4>
6.	Quelles sont les démarches à suivre pour acheter un bien immobilier au Bénin ? </h4>
<p>L'achat d'un bien immobilier au Bénin nécessite de respecter certaines formalités administratives. N'hésitez pas à contacter nos conseillers pour vous accompagner dans toutes les étapes de votre acquisition.
</p>
</section>
        <FootComponent />

    </main>
</template>
<style>
.about-item h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 1.4em;
}

.about-uss {
    justify-content: center;
}

.about-item i {
    font-size: 2.5em;
    border: 3px solid rgb(205, 127, 50);
    border-radius: 100%;
    padding: 30px;
    color: rgb(205, 127, 50);
    width: 106px;
}

.background-div {
    background-image: url('../assets/sale.jpg');
    /* Remplacez par le chemin de votre image */
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 90px;

    color: white;
    /* Texte en blanc */
}

.background-div h1,
.background-div p {
    color: white;
    /* Forcer le h1 à être en blanc */
}

.background-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Assombrissement */
    z-index: 1;
}

.background-content {
    position: relative;
    z-index: 2;
    text-align: center;
    /* Centrer le texte */
    color: white;
}
</style>