<script>
import store from '@/store' // Assurez-vous d'importer le routeur
import Swal from 'sweetalert2';
import $ from 'jquery';
export default {
    name: 'HeadComponent',
    data() {
        return {

            pays: 'BJ',
            country: null,
            currentUser: null, // Initialisez currentUser à null
            categories: null,
            keyword: null,
            ville: null,
            budget: '',
            caterid: null,
            caracteristique: null
        };
    },
    mounted() {
        document.addEventListener("DOMContentLoaded", function () {
            const collElements = document.querySelectorAll('.coll');

            collElements.forEach(element => {
                element.addEventListener('click', function () {
                    if (element.textContent.trim() === 'Voir plus') {
                        element.textContent = 'Voir moins';
                    } else {
                        element.textContent = 'Voir plus';
                    }
                });
            });
        });

        try {
            const $select = $(this.$refs.mySelect);

            $select.select2();

            $select.on('change', (event) => {
                // Gérez ici les changements de valeur de Select2
                this.pays = event.target.value;
            });
            this.getcategorie(),
                store.dispatch('categorie/getcountry')
                    .then(response => {
                        this.country = response

                    })
                    .catch(error => {
                        console.log(error)
                    })

            store.dispatch('auth/_currentUser')
                .then(() => {
                    // Vérifier si l'élément de classe mean-nav existe
                    var meanNav = document.querySelector('.mean-nav');
                    if (meanNav) {
                        // Sélectionner le ul à l'intérieur de meanNav
                        var ulElement = meanNav.querySelector('ul');
                        if (ulElement) {
                            // Supprimer l'élément avec la classe mean-last
                            var meanLastItem = ulElement.querySelector('.mean-last');
                            if (meanLastItem) {
                                meanLastItem.remove();
                            }

                            // Créer un nouvel élément li pour le lien S'abonner
                            var newListItem = document.createElement('li');
                            newListItem.innerHTML = '<a href="/abonner">S\'abonner</a>';

                            // Ajouter le nouvel élément li à la fin de la liste
                            ulElement.appendChild(newListItem);

                            // Créer un nouvel élément li pour le lien Dashboard
                            var dashboardListItem = document.createElement('li');
                            dashboardListItem.innerHTML = '<a href="/dashboard">Dashboard</a>';

                            // Ajouter le nouvel élément li à la fin de la liste
                            ulElement.appendChild(dashboardListItem);
                        }
                    }

                    this.currentUser = store.getters['auth/getCurrentUser'];
                    console.log('Utilisateur récupéré:', this.currentUser);

                })
                .catch(error => {

                    this.currentUser = null
                    console.error("Erreur lors de la récupération de l'utilisateur", error);

                });
        } catch (error) {
            console.error("Erreur lors de la récupération de l'utilisateur", error);

        }
    },
    computed: {


        formattedMaxPrix: {
            get() {
                return this.formatMoney(this.budget);
            },
            set(value) {
                // Remove non-digit characters and parse as integer
                const newValue = parseInt(value.replace(/\D/g, ''), 10);
                if (!isNaN(newValue)) {
                    this.budget = newValue;
                }
            }
        }
    },
    methods: {
        formatMoney(value) {
            // Convert value to string and add commas for thousands separator
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        formatInput(event) {
            // Get current input value
            let value = event.target.value;

            // Remove non-digit characters
            value = value.replace(/\D/g, '');

            // Format value
            value = this.formatMoney(value);

            // Update input value
            event.target.value = value;
        }
        ,
        userDemande() {
            const formData = new FormData()
            formData.append('ville', this.ville)
            formData.append('pays', this.pays)
            formData.append('budget', this.budget)
            formData.append('categorie', this.caterid)
            formData.append('caracteristique', this.caracteristique)

            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('auth/userDemande', formData)
                        .then(response => {
                            console.log(response)

                            Swal.fire({
                                title: "Succès",
                                text: 'Demande effectuer avec succès!',
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            console.log(error)

                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {

                    Swal.fire({
                        title: "Erreur",
                        text: "Erreur" + error + " lors de la récupération de l'utilisateur veuillez vous connecter ou vous inscrire",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    }).then(() => {

                        window.location.reload()
                    })

                }
                );
        },
        logout() {
            store.dispatch('auth/logout').then(data => {
                console.log(data)
                window.location.replace('/')
            }).catch(error => {
                console.log(error)
            })
        },
        search() {

            window.location.replace('/search?type=search&id=' + this.keyword)

        },
        getcategorie() {
            store.dispatch('categorie/getcategorie')
                .then(response => {
                    this.categories = response
                })
                .catch(error => {
                    console.log(error)
                })
        }

    },
}
</script>

<template>
    <header>




        <div id="header-sticky" class="header-area box-90">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-6 col-md-6 col-7 col-sm-5 d-flex align-items-center pos-relative">
                        <div class="basic-bar cat-toggle">
                            <span class="bar1"></span>
                            <span class="bar2"></span>
                            <span class="bar3"></span>
                        </div>
                        <div class="logo">

                            <a href="/"><img src="img/logo/logo2.jpg" alt=""> </a>
                        </div>

                        <div class="category-menu">
                            <h4>Catégories</h4>
                            <ul>
                                <li v-for="cat in categories" :key="cat.id"> <a
                                        :href="'/search?type=categorie&id=' + cat.id"><i
                                            class="flaticon-shopping-cart-1"></i> {{ cat.nom }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-6 col-md-8 col-8 d-none d-xl-block">
                        <div class="main-menu text-center">
                            <nav id="mobile-menu">
                                <ul>
                                    <li>
                                        <a href="/"> Home </a>
                                    </li>
                                    <li class="dropdown">
                                        <a href="#" class="dropdown-toggle"> Immobilier </a>
                                        <ul class="dropdown-menu">
                                            <li><a href="/search?type=a-vendre" class="text-sm slim-text">À vendre</a></li>
                                            <li><a href="/search?type=a-louer" class="text-sm slim-text">À louer</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/blog" > Blog </a>
                                        
                                    </li>
                                    <li>
                                        <a href="/agence-immobiliere"> Agences immobiliere</a>
                                    </li>
                                    <li v-if="currentUser">
                                        <a href="/abonner">S'abonner</a>
                                    </li>
                                    <li>
                                        <a href="/about"> Qui sommes nous ? </a>
                                    </li>
                                    <li class="d-block d-sm-none">
                                        <a v-if="currentUser == null" href="/login"> Connexion </a>
                                        <a v-else href="/dashboard"> Dashboard </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="col-xl-2 col-lg-6 col-md-6 col-5 col-sm-7 pl-0">
                        <div class="header-right f-right">
                            <ul>
                                <li class="search-btn">
                                    <a class="search-btn nav-search search-trigger" href="#"><i
                                            class="fas fa-search"></i>
                                    </a>
                                </li>
                                <li v-if="currentUser" class="login-btn"> <a href="/dashboard"><i
                                            class="far fa-user"></i> </a>
                                </li>

                                <li v-else class="login-btn"> <a href="/login"><i class="far fa-user"></i> </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-12 d-xl-none">
                        <div class="mobile-menu">

                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- Fullscreen search -->
        <div class="search-wrap">
            <div class="search-inner">
                <i class="fas fa-times search-close" id="search-close"></i>
                <div class="search-cell">
                    <form @submit.prevent="search">
                        <div class="search-field-holder">
                            <input type="search" v-model="keyword" class="main-search-input"
                                placeholder="Entrer votre recherche">
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <div class="modal fade" id="fullScreenModal" tabindex="-1" role="dialog" aria-labelledby="fullScreenModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fullScreenModalLabel"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        <div class="subscribe-inner black-bg">
                            <div class="container-fluid">


                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="m-4">
                                            <center style="color:aliceblue">Demande de devis immobilier!</center>
                                        </h3>
                                        <div class="container">
                                            <form @submit.prevent="userDemande">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label class="">Pays</label>
                                                        <select required v-model="pays" class="form-control">
                                                            <option v-for="countrie in country" :key="countrie.iso"
                                                                :value="countrie.iso">{{ countrie.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-6">

                                                        <label for="">Catégorie</label>
                                                        <select class="form-control" v-model="caterid"
                                                            aria-label="Default select example">
                                                            <option selected>Veuilllez choisir une catégorie
                                                            </option>
                                                            <option v-for="cat in categories" :key="cat.id"
                                                                :value="cat.id">{{ cat.nom
                                                                }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <label for="">Budget</label>
                                                        <input style="background-color: aliceblue;" type="text" required
                                                            v-model="formattedMaxPrix" @input="formatInput"
                                                            class="form-control">

                                                    </div>

                                                    <div class="col-md-6">

                                                        <label for="">Ville/Village ou zone</label>
                                                        <input style="background-color: aliceblue; height: 40px;"
                                                            type="text" class="form-control" v-model="ville" required>
                                                    </div>

                                                    <div class="col-12">

                                                        <label for="">Autre details</label>
                                                        <textarea class="form-control" required
                                                            v-model="caracteristique" aria-label="With textarea"
                                                            cols="3" rows="1"></textarea>

                                                    </div>
                                                    <div class="col-md-12 m-4">
                                                        <center><button class="btn theme-btn-2"
                                                                type="submit">Envoyer</button></center>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <button id="btn_dmd" type="button" style="background-color:#f08d21"
            class=" btn  text-light fixed-bottom ml-20 mr-20" data-toggle="modal" data-target="#fullScreenModal">Faire
            une demande</button>
    </header>

    <!-- header end -->
</template>

<style>
/* Basic styling for menu */
.main-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.main-menu ul li {
    position: relative;
    padding: 10px 15px;
}

.main-menu ul li a {
    text-decoration: none;
    color: #000;
}

/* Dropdown menu styling - Hidden by default */
.dropdown-menu {
    display: none; /* Ensure dropdown is hidden */
    position: absolute;
    background-color: #fff;
    padding: 10px 0;
    list-style-type: none;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.dropdown-menu li {
    padding: 5px 20px;
}

.dropdown-menu li a {
    color: #000;
    text-decoration: none;
}

/* Only show dropdown on hover */
.dropdown:hover .dropdown-menu {
    display: block; /* Show dropdown when hovering */
}


.main-menu ul li ul {
    display: none;

}

a.text-sm.slim-text{
 color:black !important

}
.modal-fullscreen {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}
</style>