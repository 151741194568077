<script>
import HeadComponent from "@/components/Head"
import FootComponent from '@/components/Foot'
import store from '@/store'
import Swal from 'sweetalert2';
import { useMeta } from 'vue-meta';

export default {
    data() {
        return {
            proprieterID: null,
            propinfo: [],
            message: null,
            commpro: null,
            validuser: null,
            currency_code: null,
            imag: null,
            cat: null,
        }
    },
    head() {
    return {
      title: this.cat,
      meta: [
        { property: 'og:title', content: this.cat },
        { property: 'og:description', content: this.propinfo.titre },
        { property: 'og:image', content: this.imag },
        { property: 'og:url', content: `https://tgvimmobrio.com/commentaire?id=${this.propinfo.id}` }
                      
      ]
    };
  }
,
    components: {
        HeadComponent,
        FootComponent
    },
    name: 'CommentaireComponent',

    methods: {

        commenter() {
            store.dispatch('auth/_currentUser')
                .then(() => {
                    store.dispatch('proprieter/commenter', { 'id': this.proprieterID, 'message': this.message })
                        .then(response => {
                            console.log(response)
                            window.location.reload()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération de l'utilisateur", error);
                }
                );
        },
        liker() {
            store.dispatch('auth/_currentUser')
                .then(() => {


                    store.dispatch('proprieter/liker', { 'id': this.proprieterID })
                        .then(response => {
                            console.log(response)
                            Swal.fire({
                                title: "Succès",
                                text: 'Like effectuer avec succès!',
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            console.log(error)

                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                })
                .catch(error => {
                    Swal.fire({
                        title: "Erreur",
                        text: "Erreur" + error + " lors de la récupération de l'utilisateur veuillez vous connecter ou vous inscrire",
                        icon: "error",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    })
                });

        },
        formatMonetaryValue(value) {
            // Convertit le nombre en chaîne de caractères
            let formattedValue = String(value);

            // Vérifie si la valeur est supérieure à 3 chiffres
            if (formattedValue.length > 3) {
                // Détermine le nombre de groupes de trois chiffres
                const groups = Math.floor((formattedValue.length - 1) / 3);

                // Initialise l'index pour l'insertion de l'espace de mille
                let index = formattedValue.length - 3;

                // Boucle pour insérer les espaces de mille
                for (let i = 0; i < groups; i++) {
                    formattedValue = formattedValue.slice(0, index) + ' ' + formattedValue.slice(index);
                    index -= 3;
                }
            }

            // Retourne la valeur formatée
            return formattedValue;
        },
        proprieteinfo(id) {
            store.dispatch('proprieter/proprieteinfo', { 'id': id })
                .then(response => {
                    console.log(response)
                    this.propinfo = response
                    this.currency_code = response.pays.currency_code
                    this.cat = this.propinfo.categorie.nom
                    this.imag = this.propinfo.image[0].image

                    document.title = this.propinfo.description
                })
                .catch(error => {
                    console.log(error)
                })
        },
        proprietercommentaires(id) {
            store.dispatch('proprieter/proprietercommentaires', { 'id': id })
                .then(response => {
                    console.log(response)
                    this.commpro = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        },
        toggleImageSize(event) {
            var clickedImageSrc = event.target.src;
            var img = document.getElementById('image')
            img.src = clickedImageSrc;
        },

        redirect(type, id) {

            if (type == 'pro') {
                this.upcontact_pro(id)

            }
            else if (type == 'us') {
                this.upcontact_us(id)

            }
            alert('Cette action est reservé aux utilisateurs inscrit, Veuillez vous connecter ')

            window.location.replace('/register')

        },
        upcontact_pro(id) {
            store.dispatch('auth/upcontact_pro', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        upcontact_us(id) {
            store.dispatch('auth/upcontact_us', { 'id': id })
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async sharecontent(desc, prix) {
            if (navigator.share) {
                try {
                    await navigator.share({
                        title: this.propinfo.categorie.nom + ' a ' + prix,
                        text: desc,
                        url: window.location.href,
                    });
                    console.log('Contenu partagé avec succès');
                } catch (error) {
                    console.error('Erreur lors du partage', error);
                }
            } else {
                alert("L'option de partage n'est pas disponible sur votre appareil.");
            }
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search)
        this.proprieterID = urlParams.get('id')
        this.proprieteinfo(this.proprieterID)
        this.proprietercommentaires(this.proprieterID)
        useMeta({

            title: this.cat,
            meta: [
                { name: 'description', content: this.propinfo.titre },
                { property: 'og:title', content: this.cat },
                { property: 'og:description', content: this.propinfo.titre },
                { property: 'og:image', content: this.imag },
                { property: 'og:url', content: `https://tgvimmobrio.com/commentaire?id=${this.propinfo.id}` }
            ]

        })
        store.dispatch('auth/_currentUser')
            .then(() => {
                this.validuser = true
            })
            .catch(error => {
                console.log(error)

            })
    }

}
</script>
<template>
    <HeadComponent />

    <main>
        <div class="blog-area pt-100 pb-60">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <article class="postbox post format-image mb-40">
                            <div class="postbox__thumb mb-35 row">
                                <div v-if="propinfo.image && propinfo.image[0].image" class="image-container col-lg-8">
                                    <img :src="propinfo.image[0].image" class="main-image" alt="blog image" id="image"
                                        style="width :900px; height :500px;'cursor " />
                                </div>
                                <div class="col-lg-4">
                                    <div v-if="propinfo.image && propinfo.image.length > 0" class="image-container">
                                        <img v-for="(image, index) in propinfo.image" :key="index" :src="image.image"
                                            class="small-image" alt="blog image" @click="toggleImageSize"
                                            style="width:100px ; height:100px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="postbox__text bg-none">
                                <div class="post-meta mb-15">
                                    <span><i class="far fa-map"></i>{{ propinfo.addresse }}</span>
                                    <span><a><i class="far fa-dollar"></i>{{ formatMonetaryValue(propinfo.prix) }} {{
                                        currency_code }}/{{ propinfo.periode }}</a></span>
                                    <a @click="liker"><span><i class="far fa-heart"></i> {{ propinfo.total_likes
                                            }}</span></a>
                                    <span><a><i class="far fa-comments"></i> {{ propinfo.total_comments }}
                                            Comments</a></span><br />
                                    <span>{{ formatDate(propinfo.created_at) }}</span>
                                </div>

                                <p>
                                    <b>
                                        <ul style="display: flex; color: red;">
                                            <li class="m-4">{{ propinfo.type }}</li>
                                            <li v-if="propinfo.nbr_pieces" class="m-4">Pièce (s) : {{
                                                propinfo.nbr_pieces }}</li>

                                            <li v-if="propinfo.nbr_parcelle" class="m-4">Parcelle (s) : {{
                                                propinfo.nbr_parcelle }}</li>

                                            <li v-if="propinfo.nbr_hectare" class="m-4">Hectare (s) : {{
                                                propinfo.nbr_hectare }}</li>
                                            <li v-if="propinfo.superficie" class="m-4">Superficie (s) : {{
                                                propinfo.superficie }} m²</li>

                                            <li v-if="propinfo.meuble" class="m-4">Meublé : OUI</li>
                                            <li v-else class="m-4">Meublé : NON</li>

                                        </ul>
                                    </b>
                                    {{ propinfo.description }}

                                </p>
                               

                                <div class="social-profile" >

                                    <a @click="liker"><i class="far fa-heart text-white"></i> </a>
                                    <a target="_blank" v-if='propinfo.user.email_contact' @click="upcontact_pro(propinfo.id)"
                                        :href="'mailto:' + propinfo.user.email"><i class="fa fa-envelope"></i></a>
                                    <a target="_blank" v-if='propinfo.user.whatsapp_contact' @click="upcontact_pro(propinfo.id)"
                                        :href="'https://wa.me/' + propinfo.user.whatsapp"><i
                                            class="fab fa-whatsapp"></i></a>

                                </div>
                            </div>
                            <div class="sharethis-inline-share-buttons"
                                :data-url="'tgvimmobrio.com/commentaire?id=' + propinfo.id" :data-title="cat"
                                :data-description="propinfo.titre" :data-image="imag">
                            </div>

                            <div class="post-comments">
                                <div class="blog-coment-title mb-30">
                                    <h5>commentaires</h5>
                                </div>
                                <div class="latest-comments">
                                    <ul>
                                        <li v-for="com in commpro" :key="com.id">
                                            <div class="comments-box">
                                                <div class="comments-avatar">
                                                    <img :src="com.user.photo" alt="">
                                                </div>
                                                <div class="comments-text">
                                                    <div class="avatar-name">
                                                        <h5>{{ com.user.email }}</h5>
                                                        <span>{{ formatDate(com.created_at) }}</span>
                                                    </div>
                                                    <p>{{ com.message }}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="post-comments-form">
                                <div class="post-comments-title">
                                    <h2>Envoyer un commentaire</h2>
                                </div>
                                <form id="contacts-form" class="conatct-post-form" @submit.prevent="commenter">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="contact-icon contacts-message">
                                                <textarea required v-model="message" name="comments" id="comments"
                                                    cols="30" rows="10"
                                                    placeholder="Votre commentaire ici...."></textarea>
                                            </div>
                                        </div>

                                        <div class="col-xl-12">
                                            <button class="btn theme-btn" type="submit">Envoyer</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <!-- blog-area end -->


    </main>

    <FootComponent />
</template>
<style scoped>
.main-image {
    flex: 1;
    cursor: pointer;
}

.small-image {
    width: 150px;
    height: 150px;
    margin: 10px;
    cursor: pointer;
}
</style>