import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Agence from '@/views/Agences.vue'
import Commentaire from '@/views/Commentaire.vue'
import Forget from '@/views/Forget.vue'
import Search from '@/views/Search.vue'
import Pin from '@/views/Pin.vue'
import Rpin from '@/views/Rpin.vue'
import Changepass from '@/views/Changepass.vue'
import Dashboard from '@/views/Dashboard.vue'
import Profil from '@/views/Profil.vue'
import Users from '@/views/Users.vue'
import Proprieter from '@/views/Proprieter.vue'
import Demande from '@/views/Demande.vue'
import Categorie from '@/views/Categorie.vue'
import Add from '@/views/Add.vue'
import Banniere from '@/views/Banniere.vue'
import Article from '@/views/Article.vue'
import Pub from '@/views/Pub.vue'
import Updateproprieter from '@/views/Updateproprieter.vue' 
import Updatearticle from '@/views/Updatearticle.vue' 
import Abonner from '@/views/Abonner.vue' 
import Dashboard_user from '@/views/Dashboard_user.vue'
import News from '@/views/News.vue'
import Add_article from '@/views/Add_article.vue'
import Adm_article from '@/views/Adm_article.vue'
import Blog from '@/views/Blog.vue'
import Article_commentaire from '@/views/Article_commentaire.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/agence-immobiliere',
    name: 'Agence',
    component: Agence,
  },
  {
    path: '/commentaire',
    name: 'Commentaire',
    component: Commentaire,
  },
  {
    path: '/article_commentaire',
    name: 'Article_commentaire',
    component: Article_commentaire ,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{noindex:true}
  },{
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{noindex:true}
  },{
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget,
  },
  {
    path: '/pin',
    name: 'Pin',
    component: Pin,
  },
  {
    path: '/rpin',
    name: 'Rpin',
    component: Rpin,
  },
  {
    path: '/changepass',
    name: 'Changepass',
    component: Changepass,
  },
  {
    path: '/categorie',
    name: 'Categorie',
    component: Categorie,
  }
  ,
  {
    path: '/banniere',
    name: 'Banniere',
    component: Banniere,
  },
  {
    path: '/pub',
    name: 'Pub',
    component: Pub,
  }
  ,{
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },{
    path: '/dashboard_user',
    name: 'Dashboard_user',
    component: Dashboard_user,
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
  },{
    path: '/users',
    name: 'Users',
    component: Users,
  },{
    path: '/abonner',
    name: 'abonner',
    component: Abonner,
  },
  {
path:'/article',
name: 'article',
component: Article,
  },
  {
    path: '/proprieter',
    name: 'Proprieter',
    component: Proprieter,
  }
  ,{
    path: '/demande',
    name: 'Demande',
    component: Demande,
  },{
    path: '/add',
    name: 'Add',
    component: Add,
  },{
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/add_article',
    name: 'Add_article',
    component: Add_article,
  },
  
  {
    path: '/adm_article',
    name: 'Adm_article',
    component: Adm_article,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
  },{
    path:'/updateproprieter',
    name:'Updateproprieter',
    component:Updateproprieter,
  },{
    path:'/updatearticle',
    name:'Updatearticle',
    component:Updatearticle,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
