import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import { createMetaManager } from 'vue-meta';

const app = createApp(App);
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
            console.log('Service Worker registered:', registration);
        })
        .catch(error => {
            console.error('Service Worker registration failed:', error);
        });
}

// Utiliser le gestionnaire de métadonnées
const metaManager = createMetaManager();
app.use(metaManager);
app.use(router);
app.use(VueTelInput);
app.mount('#app');
