<script>
import AsideComponent from "@/components/Asideadmin"
import HeadComponent from "@/components/Headadmin"
import FootComponent from "@/components/Footadmin"
import store from "@/store"
import $ from 'jquery'
import Swal from 'sweetalert2';
import 'datatables.net';
export default {

    components: {
        HeadComponent,
        AsideComponent,
        FootComponent
    },
    data() {

        return {
            users: null,
            
            dataTable: null,

        }
    },
    name: 'UsersComponent',
    mounted() {

        store.dispatch('auth/users')
            .then(response => {
                console.log(response)
                this.users = response 
                this.$nextTick(() => {
          this.initializeDataTable();
        });
            })
            .catch(error => {
                console.log(error)
            })
    },
    methods: {
        initializeDataTable() {
          // Détruire le DataTable existant si nécessaire
      if ($.fn.DataTable.isDataTable('#Table')) {
        $('#Table').DataTable().destroy();
      }
      // Initialisation de DataTable
      $('#Table').DataTable();
    },
        block_user(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous bloquer/debloquer cet utilisateurs  ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/block_user', { "id": id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        },


        update_user_top(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous changer l'importance de  cet utilisateurs  ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/update_user_top', { "id": id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        }
        ,
        update_user_email(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous changer l'etat email de  cet utilisateurs  ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/update_user_email', { "id": id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        }
        ,
        update_user_whatsapp(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous changer l'etat whatsapp de  cet utilisateurs  ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/update_user_whatsapp', { "id": id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        }
        ,
        update_user_max_cat(id) {
            Swal.fire({
                title: "Entrez le nombre maximum d'abonnement autoriser pour ce utilisateur ",
                input: 'number',
                inputPlaceholder: "nombre d'abonnement",
                showCancelButton: true,
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {

                    store.dispatch('auth/update_user_max_cat', { "id": id, 'max': result.value })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })

                }
            })


        },
        update_user_dmd(id) {
            Swal.fire({
                title: "Question",
                text: "Voulez vous changer le droit de demande de cet utilisateur?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non',
            }).then(async result => {
                if (result.value) {
                    store.dispatch('auth/update_user_dmd', { "id": id })
                        .then(response => {
                            Swal.fire({
                                title: "Succès",
                                text: response.message,
                                icon: "success",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            }).then(() => {
                                window.location.reload()
                            })
                        })
                        .catch(error => {
                            Swal.fire({
                                title: "Erreur",
                                text: error.data.errors,
                                icon: "error",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            })
                        })
                }
            })

        }



    }

}



</script>
<template>

    <body class="g-sidenav-show  bg-gray-200" id="body">
        <AsideComponent />

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <!-- Navbar -->
            <HeadComponent />
            <!-- End Navbar -->
            <div class="container-fluid py-4">
                <div class="row">
                    <div class="col-12">
                        <div class="card my-4">
                            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                                    <h6 class="text-white text-capitalize ps-3">Utilisateurs</h6>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-2">
                                <div class="table-responsive p-0">
                                    <table id="Table" class="table align-items-center mb-0">
      <thead>
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Utilisateur</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Adresse</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Entreprise</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">URL</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Role</th>
          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Importance</th>
          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Demande</th>
          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>
            <div class="d-flex px-2 py-1">
              <div>
                <img :src=" user.photo" class="avatar avatar-md me-3 border-radius-lg" alt="user2">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">{{ user.first_name }} {{ user.last_name }}</h6>
                <p class="text-xs text-secondary mb-0">email: {{ user.email }}</p>
                <p class="text-xs text-secondary mb-0">whatsapp: {{ user.whatsapp }}</p>
              </div>
            </div>
          </td>
          <td class="align-middle text-center">
            <span class="text-secondary text-xs font-weight-bold">{{ user.addresse }}</span>
          </td>
          <td>
            <p v-if="user.entreprise" class="text-xs font-weight-bold mb-0">{{ user.entreprise }}</p>
          </td>
          <td class="align-middle">
            <a v-if="user.url" :href="user.url" target="_blank" class="text-secondary font-weight-bold text-xs">
              <button class="btn btn-dark">SITE WEB</button>
            </a>
          </td>
          <td>
            <p class="text-xs font-weight-bold mb-0">Utilisateur</p>
            <p class="text-xs text-secondary mb-0">{{ user.role }}</p>
          </td>
          <td class="align-middle text-center text-sm">
            <span v-if="user.is_active && !user.is_staff" class="badge badge-sm bg-gradient-primary">Actif</span>
            <span v-else-if="!user.is_active && !user.is_staff" class="badge badge-sm bg-gradient-secondary">Inactif</span>
            <span v-else class="badge badge-sm bg-gradient-danger">bloquer</span>
          </td>
          <td class="align-middle text-center text-sm">
            <span v-if="user.top" class="badge badge-sm bg-gradient-primary">TOP</span>
            <span v-else class="badge badge-sm bg-gradient-danger">NON TOP</span>
          </td>
          <td class="align-middle text-center text-sm">
            <span v-if="user.dmd" class="badge badge-sm bg-gradient-primary">DEMANDE ACTIF</span>
            <span v-else class="badge badge-sm bg-gradient-danger">DEMANDE NON ACTIF</span>
          </td>
          <td class="align-middle">
            <a :href="'/dashboard_user?id=' + user.id" class="text-success font-weight-bold text-xs">
              <button class="btn btn-dark m-2"><i class="opacity-10 material-icons">visibility</i> statistique</button>
            </a>
            <a :href="'/search?type=user&id=' + user.id" class="text-secondary font-weight-bold text-xs">
              <button class="btn btn-dark m-2"><i class="opacity-10 material-icons">receipt_long</i> Biens</button>
            </a>
            <button v-if="!user.top" class="btn btn-warning m-2" @click="update_user_top(user.id)">
              <i class="opacity-10 material-icons">star</i> top/non top
            </button>
            <button v-else class="btn btn-info m-2" @click="update_user_top(user.id)">
              <i class="opacity-10 material-icons">star</i> top/non top
            </button>
            <button v-if="!user.email_contact" class="btn btn-warning m-2" @click="update_user_email(user.id)">
              <i class="opacity-10 material-icons">mail</i> email
            </button>
            <button v-else class="btn btn-info m-2" @click="update_user_email(user.id)">
              <i class="opacity-10 material-icons">mail</i> email
            </button>

            <button v-if="!user.whatsapp_contact" class="btn btn-warning m-2" @click="update_user_whatsapp(user.id)">
              <i class="opacity-10 material-icons">chat</i>whatsapp
            </button>
            <button v-else class="btn btn-info m-2" @click="update_user_whatsapp(user.id)">
              <i class="opacity-10 material-icons">chat</i> whatsapp
            </button>


            <button v-if="!user.dmd" class="btn btn-warning m-2" @click="update_user_dmd(user.id)">
              <i class="opacity-10 material-icons">assignment</i> devis/non devis
            </button>
            <button v-else class="btn btn-info m-2" @click="update_user_dmd(user.id)">
              <i class="opacity-10 material-icons">assignment</i> devis/non devis
            </button>
            <button class="btn btn-primary m-2" @click="update_user_max_cat(user.id)">
              {{ user.max_abonne }} abonnement
            </button>
            <button v-if="!user.is_staff" class="btn btn-danger m-2" @click="block_user(user.id)">
              <i class="opacity-10 material-icons">block</i>
            </button>
            <button v-else class="btn btn-success m-2" @click="block_user(user.id)">
              <i class="opacity-10 material-icons">check</i> activer/bloquer
            </button>
          </td>
        </tr>
      </tbody>
    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FootComponent />
            </div>
        </main>
    </body>
</template>